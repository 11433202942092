
import { getAuth, signOut, signInWithCustomToken } from "firebase/auth";
/*
const authConfig = {
    clientId: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
    redirectUri: window.location.origin + "/apps/teams/auth/end-microsoft",
    cacheLocation: "localStorage",
    navigateToLoginRequestUrl: false,
};*/


class MicrosoftTeamsWrapper {

    constructor(context, microsoftTeams, teamsContext) {
      this.context = context;
      this.microsoftTeams = microsoftTeams;
      this.teamsContext = teamsContext;
    }

    showPurchaseOptions() { return !this.hidePurchaseOptions(); }
    hidePurchaseOptions() {
        // iOS policy restrictions prevent direct upgrade path
        //"hostClientType": "The type of host client. Possible values are android, ios, web, desktop, surfaceHub, teamsRoomsAndroid, teamsPhones, teamsDisplays rigel (deprecated, use teamsRoomsWindows instead)"
        return this.teamsContext 
            && (this.teamsContext.hostClientType === 'ios' || this.teamsContext.hostClientType === 'android');
    }

    getTeamsTheme() {
        return this.teamsContext && this.teamsContext.theme; // The current UI theme: default | dark | contrast
    }

    isDarkTheme() {
        return this.getTeamsTheme() === 'dark' || this.getTeamsTheme() === 'contrast';
    }

    resultToEntityId(event, race, result) {
        return `result:${event.id}:${race.id}:${result.id}`;
    }

    parseEntityId(entityId) {
      const parts = entityId.split(':');
      if (!parts || parts.length <= 1) {
            return null;
        }
        if (parts[0] === 'result' && parts.length == 4) {
            return {
                type: parts[0],
                event_id: parts[1],
                race_id: parts[2],
                result_id: parts[3],
            }
        }
        return null;
    }

    getTeamsInfo() {
        if (this.teamsContext == null) return null;
        return {
            tenant_id: this.teamsContext.tid,
            group_id: this.teamsContext.groupId,
            team_id: this.teamsContext.teamId,
            channel_id: this.teamsContext.channelId,
            chat_id: this.teamsContext.chatId,
        };
    }

    async signOut() {
        const auth = getAuth();
        await signOut(auth)
        this.context.$store.dispatch('setUser', null);
    }

    async login() {
        var self = this;
        this.microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/apps/teams/auth/start",
            width: 600,
            height: 535,
            successCallback: function (result) {
                console.log("AUTH Login succeeded: ", result, 'user', self.context.user);
                const auth = getAuth();
                signInWithCustomToken(auth, result.token).then(response => {
                    if (response && response.user) {
                        console.log("AUTH Token claimed successfully: ", response.user);
                    }
                });
            },
            failureCallback: function (reason) {
                console.log("Login failed: " + reason);
                if (reason === "CancelledByUser" || reason === "FailedToOpenWindow") {
                    console.log("Login was blocked by popup blocker or canceled by user.");
                }
            }
        });
    }

}

export default MicrosoftTeamsWrapper;